<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
window.pro_version = "2023_05_16";
export default {
  name: "App",
  created() {
    // 初始化设备信息
    let winWidth = window.innerWidth;
    let deviceName;
    if (winWidth > 1179) {
      deviceName = "pc";
    } else if (winWidth > 991) {
      deviceName = "pad";
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 750;
      } else if (deviceWidth < 320) {
        deviceWidth = 320;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
    } else {
      deviceName = "mobile";
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 750;
      } else if (deviceWidth < 320) {
        deviceWidth = 320;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
    }
    window.deviceName = deviceName;
  },
};
</script>

<style lang="stylus">
@import '~@/assets/css/reset.css';
</style>
<style lang="scss">
.h5-container {
  padding: 0 0.32rem;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
/*
 home 首页
 about  关于我们
 cloudEcology 云生态
 customization 定制化
 demo 体验demo
 multiIndustry  多行业文件夹
 beauty 美容
 hairdressing 美发
 manicure 美甲美睫
 skin  皮肤管理
 smartRetail 智慧零售
 supplyChain 供应链
 tanning 美黑
 tattoo 纹身
 treatment 中医理疗
*/
const routes = [
  {
    path: '/',
    name:'home',
    component: () => import('@/views/home.vue')
  },{
    path: '/about',
    name:'about',
    component: () => import('@/views/about.vue')
  },{
    path: '/cloudEcology',
    name:'cloudEcology',
    component: () => import('@/views/cloudEcology.vue')
  },{
    path: '/customization',
    name:'customization',
    component: () => import('@/views/customization.vue')
  },{
    path: '/demo',
    name:'demo',
    component: () => import('@/views/demo.vue')
  },{
    path: '/beauty',
    name:'beauty',
    component: () => import('@/views/multiIndustry/beauty.vue')
  },{
    path: '/hairdressing',
    name:'hairdressing',
    component: () => import('@/views/multiIndustry/hairdressing.vue')
  },{
    path: '/manicure',
    name:'manicure',
    component: () => import('@/views/multiIndustry/manicure.vue')
  },{
    path: '/skin',
    name:'skin',
    component: () => import('@/views/multiIndustry/skin.vue')
  },{
    path: '/smartRetail',
    name:'smartRetail',
    component: () => import('@/views/multiIndustry/smartRetail.vue')
  },{
    path: '/supplyChain',
    name:'supplyChain',
    component: () => import('@/views/multiIndustry/supplyChain.vue')
  },{
    path: '/tanning',
    name:'tanning',
    component: () => import('@/views/multiIndustry/tanning.vue')
  },{
    path: '/tattoo',
    name:'tattoo',
    component: () => import('@/views/multiIndustry/tattoo.vue')
  },{
    path: '/treatment',
    name:'treatment',
    component: () => import('@/views/multiIndustry/treatment.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

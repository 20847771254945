import { createApp } from "vue";
import App from './App.vue'
import router from './router';
import Axios from './assets/js/axios'

// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'animate.css/animate.min.css'

const app = createApp(App);
app.use(router)
app.use(ElementPlus)
app.use(Axios)
app.mount('#app')

